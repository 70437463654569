import Chart from 'chart.js/auto';
import { fetchJson } from '../../../fetch';

/**
 *
 */
function transparentize (color, opacity) {
  return color
    .replace('rgb', 'rgba')
    .replace(')', `, ${opacity.toString()})`);
}

class QuickChart {
  /**
   *
   */
  static getCanvas (id, width, height) {
    return `<canvas id="${id}" width="${width}" height="${height}"></canvas>`;
  }

  /**
   *
   */
  constructor (canvasId, type, xLabels, options = {}) {
    this.canvas = document.getElementById(canvasId).getContext('2d');
    this.options = options;
    this.options.type = type;
    this.options.data = {
      labels: xLabels,
      datasets: []
    };
  }

  /**
   *
   */
  /* eslint-disable-next-line */
  async populate (endpoint, map) {
    return fetchJson(endpoint, map);
  }

  /**
   *
   */
  usePercentageLabels () {
    this.options.options = {
      scales: {
        yAxes: [{
          beginAtZero: true,
          min: 0,
          max: 100,
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            stepSize: 20,
            callback: value => `${value}%`
          }
        }]
      },
      responsive: false,
      legend: {
        position: 'bottom',
        align: 'start'
      },
      plugins: {
        title: {
          display: false
        }
      }
    };

    return this;
  }

  /**
   *
   */
  usePolarityLabels () {
    this.options.options = {
      scales: {
        yAxes: [{
          beginAtZero: true,
          min: -100,
          max: 100,
          ticks: {
            suggestedMin: -100,
            suggestedMax: 100,
            stepSize: 25,
            callback: value => (value / 100).toString().replace('0.', '.')
          }
        }],
        xAxes: [{
          ticks: {
            display: false
          }
        }]
      },
      responsive: false,
      legend: {
        display: false
      },
      plugins: {
        title: {
          display: false
        }
      }
    };

    return this;
  }

  /**
   *
   */
  setData (label, color, data, fill = false) {
    this.options.data.datasets.push({
      label,
      data,
      borderColor: color,
      backgroundColor: color instanceof Function
        ? color
        : transparentize(color, 0.1),
      tension: 0.25,
      fill
    });

    return this;
  }

  /**
   *
   */
  render () {
    if (this.chart === undefined) {
      const options = { ...this.options };
      this.chart = new Chart(this.canvas, options);
    }

    return this;
  }
}

export default QuickChart;
