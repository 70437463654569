import { initLazyImageLoader } from './lazy-image-loader';
import { initAnalytics } from './components/analytics';
import { initSplash } from './components/splash';

export async function initApp (): Promise<void> {
  initLazyImageLoader();

  try {
    await initSplash();
  } catch (e) {
    console.error(e);
  }

  // Initialize all other components concurrently with dynamic imports.
  await Promise.allSettled([
    import('./components/ephemeral-experiences').then(module => module.initPageTotalExperiencesCaptured()),
    import('./components/navigation').then(module => module.initNavigation()),
    import('./components/go_vote_reminders').then(module => module.initGoVoteReminders()),
    import('./components/yt_carousel').then(module => module.initYtCarousel()),
    import('./components/tech-giants-exposed').then(module => module.initTechGiantsExposed()),
    import('./components/bias-graphs').then(module => module.initBiasGraphs()),
    import('./components/elections-flipped').then(module => module.initElectionsFlipped()),
    import('./components/google-reshaping-elections').then(module => module.initGoogleReshapingElections()),
    import('./components/balanced-data-collection').then(module => module.initBalancedDataCollection()),
    import('./components/footer').then(module => module.initFooter())
  ]);

  // initBiasNumbers & initInfoBoxes modifies the DOM generated by initBiasGraphs,
  // so it must be called after the DOM is modified.
  await Promise.allSettled([
    import('./components/current-bias-boxes').then(module => module.initCurrentBiasBoxes()),
    import('./components/info-box').then(module => module.initInfoBoxes()),
    import('./components/count_down_timer').then(module => module.initCountDowntimer()),
    import('./components/info-video').then(module => module.initInfoVideo())
  ]);

  await initAnalytics();

  console.log('Initialized');
}
