import { getBiasInfo } from '../../util';
import { fetchAllJsonCached } from '../../fetch-all-json';

const bias_classes = {
  extLib: 'bias-liberal-extreme',
  midLib: 'bias-liberal-significant',
  sltLib: 'bias-liberal-minimal',
  neutral: 'bias-mixed',
  sltCon: 'bias-conservative-minimal',
  midCon: 'bias-conservative-significant',
  extCon: 'bias-conservative-extreme'
};

const biasClassNames = Object.values(bias_classes);

async function fetchBiasNumbers () {
  const response = await fetchAllJsonCached();
  return response.bias_ratings;
}

/**
 *
 */
function populateBias (target, secondRating, biasClasses, top, right = 0) {
  const number = parseFloat(secondRating);
  const [level, biasDescption] = getBiasInfo(number);

  target.number.innerHTML = number.toFixed(3);
  target.description.innerHTML = biasDescption;

  for (const className of biasClassNames) {
    target.box.classList.remove(className);
  }

  target.box.classList.add(biasClasses[level]);
  target.box.style.top = top;
  target.box.style.right = right;
}

/**
 *
 */
async function biasNumbers () {
  const google = {
    number: document.getElementById('google_bias_number'),
    description: document.getElementById('google_bias_desc'),
    box: document.getElementById('google_bias_box')
  };

  const bing = {
    number: document.getElementById('bing_bias_number'),
    description: document.getElementById('bing_bias_desc'),
    box: document.getElementById('bing_bias_box')
  };

  const yahoo = {
    number: document.getElementById('yahoo_bias_number'),
    description: document.getElementById('yahoo_bias_desc'),
    box: document.getElementById('yahoo_bias_box')
  };

  const youtube = {
    number: document.getElementById('youtube_bias_number'),
    description: document.getElementById('youtube_bias_desc'),
    box: document.getElementById('youtube_bias_box')
  };

  try {
    const data = await fetchBiasNumbers();

    for (const key of Object.keys(data.platform_bias)) {
      const secondRating = data.platform_bias[key].second_rating;

      switch (key) {
        case 'google':
          populateBias(google, secondRating, bias_classes);
          break;
        case 'bing':
          populateBias(bing, secondRating, bias_classes);
          break;
        case 'yahoo':
          populateBias(yahoo, secondRating, bias_classes);
          break;
        case 'youtube':
          // populateBias(youtube, secondRating, bias_classes); // @TODO: uncomment this when youtube data is ready
          populateBias(youtube, data.platform_bias.google.second_rating, bias_classes); // @TODO: delete this when youtube data is ready
          break;
      }
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error.message);
  }
}

/**
 *
 */
export async function initCurrentBiasBoxes () {
  await biasNumbers();
  setInterval(async () => {
    await biasNumbers();
  }, 270000);
}
