export function isDebug () {
  const isEnabled = window.location.search.includes('debug=1');
  return isEnabled;
}

export function isDev () {
  const isEnabled = window.location.hostname.includes('dev.americasdigitalshield.');
  return isEnabled;
}

export function isGeoChartEnabled () {
  const isEnabled = window.location.search.includes('geo=1');
  return isEnabled;
}

const BIAS_DESCRIPTION = {
  extLib: 'Extreme Liberal Bias',
  midLib: 'Significant Liberal Bias',
  sltLib: 'Minimal Liberal Bias',
  extCon: 'Extreme Conservative Bias',
  midCon: 'Significant Conservative Bias',
  sltCon: 'Minimal Conservative Bias',
  neutral: 'Neutral - No Bias'
};

export function getBiasInfo (bnum) {
  if (bnum <= -0.2) {
    return ['extLib', BIAS_DESCRIPTION.extLib];
  }
  if (bnum > -0.2 && bnum <= -0.05) {
    return ['midLib', BIAS_DESCRIPTION.midLib];
  }
  if (bnum > -0.05 && bnum < 0) {
    return ['sltLib', BIAS_DESCRIPTION.sltLib];
  }
  if (bnum === 0) {
    return ['neutral', BIAS_DESCRIPTION.neutral];
  }

  if (bnum > 0 && bnum <= 0.05) {
    return ['sltCon', BIAS_DESCRIPTION.sltCon];
  }
  if (bnum > 0.05 && bnum <= 0.2) {
    return ['midCon', BIAS_DESCRIPTION.midCon];
  }
  if (bnum > 0.2) {
    return ['extCon', BIAS_DESCRIPTION.extCon];
  }
  console.error('Unknown bias level:', bnum);
  return ['unknown', 'unknown'];
}

export function mapValues (t, x0, x1, y0, y1) {
  if (x0 === x1 || y0 === y1) {
    return y0;
  }
  return ((t - x0) * (y1 - y0)) / (x1 - x0) + y0;
}

export function interpolate (t, x0, x1) {
  return mapValues(t, 0, 1, x0, x1);
}

export function setCssRootVar (name, value) {
  document.documentElement.style.setProperty(name, value);
}

export function isMobile () {
  const params = window.location.search;
  if (params.includes('m=1')) {
    return true;
  }
  if (params.includes('m=0')) {
    return false;
  }
  // First step.
  const is_mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (is_mobile) {
    return is_mobile;
  }
  return false;
}
