import './style.css';
import htmlFrag from './content.frag.html';
import { loadHtmlFragment } from '../../load-html-fragment';
import QuickChart from './charts';
import { fetchJson } from '../../fetch';
import { isDebug } from '../../util';

const querySelector = '#vote-reminders';
const JSON_ENDPOINT = '/api/all.json';

export async function initGoVoteReminders () {
  if (!isDebug()) {
    return;
  }

  // for each div with class go-vote-reminder-disabled
  const $disabled = document.querySelectorAll('.go-vote-reminder-disabled');
  $disabled.forEach($div => {
    // delete instead
    $div.style.removeProperty('display');
    $div.classList.remove('go-vote-reminder-disabled');
  });

  const data = await fetchJson(JSON_ENDPOINT);
  const { time, liberal, conservative, moderate } = {
    ...data.go_vote_reminders_data || {},
    time: [0, 1, 2, 3, 4, 5],
    liberal: [95, 60, 30, 40, 50, 70],
    conservative: [5, 30, 60, 15, 25, 20],
    moderate: [0, 10, 10, 45, 25, 10]
  };

  loadHtmlFragment(querySelector, htmlFrag);

  new QuickChart('goVoteChart', 'line', time)
    .usePercentageLabels()
    .setData('Liberal', 'blue', liberal)
    .setData('Conservative', 'red', conservative)
    .setData('Moderate', 'purple', moderate)
    .render();
}
