import './style.css';
import { initGoogleGraph } from './google';
import { initBingBias } from './bing';
import { initYoutubeGraph } from './youtube';
import { initYahooGraph } from './yahoo';
import { initMissionGraph } from './mission';
import { initFacebookGraph } from './facebook';
import { initTikTokGraph } from './tiktok';
import { initXGraph } from './x';

const largeContainer = '#bias-graph-large';
const firstSmallContainer = '#bias-graph-small-first';
const secondSmallContainer = '#bias-graph-small-second';

const isOkay = result => result === true;

/**
 *
 */
export async function initBiasGraphs () {
  const graphs = await Promise.allSettled([
    // Large graphs
    Promise.allSettled([
      initGoogleGraph(largeContainer),
      initBingBias(largeContainer)
    ]),

    // First row of small graphs
    Promise.allSettled([
      initMissionGraph(firstSmallContainer),
      initYahooGraph(firstSmallContainer),
      initYoutubeGraph(firstSmallContainer)
    ]),

    // Second row of small graphs
    Promise.allSettled([
      initFacebookGraph(secondSmallContainer, false),
      initTikTokGraph(secondSmallContainer, false),
      initXGraph(secondSmallContainer, true)
    ])
  ]);

  // const allOk = graphs[0].every(isOkay) && graphs[1].every(isOkay) && graphs[2].every(isOkay);
  // this gives us a type error on safari: TypeError: graphs[0].every is not a function
  // so we need to use a for loop instead
  try {
    let allOk = true;
    for (let i = 0; i < graphs.length; i++) {
      allOk = allOk && graphs[i].value.every(isOkay);
    }

    return allOk;
  } catch (e) {
    console.error('Error in initBiasGraphs', e);
    return false;
  }
}
