import Chart from 'chart.js/auto';

class QuickChart {
  /**
   *
   */
  constructor (canvasId, type, width = 475, height = 165, options = {}) {
    this.canvas = document.querySelector(canvasId);
    this.canvas.height = height;
    this.canvas.width = width;

    this.options = options;
    this.options.type = type;
    this.rendered = false;
    this.options.data = {
      datasets: []
    };
    this.context2d = this.canvas.getContext('2d');
    this.chart = new Chart(this.context2d, options);
  }

  setAll (data, options) {
    this.chart.data = data;
    this.chart.options = options;
  }

  update () {
    this.chart.update();
  }
}

export default QuickChart;
